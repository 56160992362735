@import '../variables';
.list-abbr {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: $light-color;
  background-color: $primary-color;
  border-radius: 50%;
  text-align: center;
}
