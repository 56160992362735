/*
 * Mixin: Material Shadow
 * By: Lucas Buetto De Angelis
 * Changed: Vivi da Motinha
 * Url: https://gist.github.com/lucasbuetto/19532920b36e70a52f9dba62d8838985 (provisory)
*/


@mixin material-shadow($level: 1, $type: "box", $color: #000000) {
    @if $type=="text" {
        @if $level==1 {
            text-shadow: 0 1px 3px rgba($color, 0.12), 0 1px 2px rgba($color, 0.24);
        }

        @else if $level==2 {
            text-shadow: 0 3px 6px rgba($color, 0.16), 0 3px 6px rgba($color, 0.23);
        }

        @else if $level==3 {
            text-shadow: 0 10px 20px rgba($color, 0.19),
                0 6px 6px rgba($color, 0.23);
        }

        @else if $level==4 {
            text-shadow: 0 14px 28px rgba($color, 0.25),
                0 10px 10px rgba($color, 0.22);
        }

        @else if $level==5 {
            text-shadow: 0 19px 38px rgba($color, 0.3),
                0 15px 12px rgba($color, 0.22);
        }
    }

    @else {
        @if $level==1 {
            box-shadow: 0 1px 3px rgba($color, 0.06), 0 1px 2px rgba($color, 0.12);
        }

        @else if $level==2 {
            box-shadow: 0 3px 6px rgba($color, 0.16), 0 3px 6px rgba($color, 0.23);
        }

        @else if $level==3 {
            box-shadow: 0 10px 20px rgba($color, 0.19), 0 6px 6px rgba($color, 0.23);
        }

        @else if $level==4 {
            box-shadow: 0 14px 28px rgba($color, 0.25),
                0 10px 10px rgba($color, 0.22);
        }

        @else if $level==5 {
            box-shadow: 0 19px 38px rgba($color, 0.3),
                0 15px 12px rgba($color, 0.22);
        }
    }
}

@mixin card($depth) {
    @if $depth < 1 {
        box-shadow: none;
    }

    @else if $depth>5 {
        @warn "Invalid $depth `#{$depth}` for mixin `card`.";
    }

    @else {
        box-shadow: bottom-shadow($depth), top-shadow($depth);
    }
}



.text-depth-1 {
    @include material-shadow(1, "text");
}

.text-depth-2 {
    @include material-shadow(2, "text");
}

.text-depth-3 {
    @include material-shadow(3, "text");
}

.text-depth-4 {
    @include material-shadow(4, "text");
}

.text-depth-5 {
    @include material-shadow(5, "text");
}

.input-depth-1 {
    @extend .form-control;
    @include material-shadow(1, "box");
}

.input-depth-2 {
    @extend .form-control;

    @include material-shadow(2, "box");
}

.input-depth-3 {
    @extend .form-control;

    @include material-shadow(3, "box");
}

.input-depth-4 {
    @extend .form-control;

    @include material-shadow(4, "box");
}

.input-depth-5 {
    @extend .form-control;

    @include material-shadow(5, "box");
}


.card-depth-1 {
    @extend .card;
    @include material-shadow(1);
}

.card-depth-2 {
    @extend .card;
    @include material-shadow(2);
}

.card-depth-3 {
    @extend .card;
    @include material-shadow(3);
}

.card-depth-4 {
    @extend .card;
    @include material-shadow(4);
}

.card-depth-5 {
    @extend .card;
    @include material-shadow(5);
}

.card-hover {
    @extend .card;
    @include card(1);
    transition: all 250ms;

    &:hover {
        @include card(5);
        transform: scale(1.01);
    }
}
