.default-setup {

    :host ::ng-deep .p-dropdown>span {
        align-self: center !important;
        margin-left: 12px;

        height: 48.59px !important;

    }
}
