.form-control {
  padding: $input-btn-padding-y 20px;
  font-size: 16px !important;
}
.custom-file-label {
  &:after {
    background: linear-gradient(56deg, $primary-color 0%, $primary-color-2 100%);
    border: none;
  }
}
